<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form" title="Stacked Form">
        <h4 slot="header" class="card-title">Add New member</h4>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submit)">
            <div>
              <div class="col-md-8">
                <ValidationProvider
                  name="First Name"
                  rules="required|min:3"
                  v-slot="{ passed, failed, errors }"
                >
                  <base-input
                    required
                    label="First Name"
                    v-model="model.firstName"
                    type="text"
                    placeholder="Enter First name"
                    :error="errors[0]"
                    :class="[
                      { 'has-success': passed },
                      { 'has-danger': failed },
                    ]"
                  >
                  </base-input>
                </ValidationProvider>
              </div>
              <div class="col-md-8">
                <ValidationProvider
                  name="Last Name"
                  rules="required|min:3"
                  v-slot="{ passed, failed, errors }"
                >
                  <base-input
                    required
                    label="Last Name"
                    v-model="model.lastName"
                    type="text"
                    placeholder="Enter Last name"
                    :error="errors[0]"
                    :class="[
                      { 'has-success': passed },
                      { 'has-danger': failed },
                    ]"
                  >
                  </base-input>
                </ValidationProvider>
              </div>
              <div class="col-md-8">
                <ValidationProvider
                  name="Position"
                  rules="required|min:3"
                  v-slot="{ passed, failed, errors }"
                >
                  <base-input
                    required
                    label="Position"
                    v-model="model.position"
                    type="text"
                    placeholder="Enter position"
                    :error="errors[0]"
                    :class="[
                      { 'has-success': passed },
                      { 'has-danger': failed },
                    ]"
                  >
                  </base-input>
                </ValidationProvider>
              </div>
              <div class="col-md-8">
                <ValidationProvider
                  name="Phone"
                  rules="required|numeric"
                  v-slot="{ passed, failed, errors }"
                >
                  <base-input
                    required
                    label="Phone"
                    v-model="model.phone"
                    type="text"
                    placeholder="Enter phone"
                    :error="errors[0]"
                    :class="[
                      { 'has-success': passed },
                      { 'has-danger': failed },
                    ]"
                  >
                  </base-input>
                </ValidationProvider>
              </div>
              <div class="col-md-8">
                <ValidationProvider
                  name="Email"
                  rules="required|email"
                  v-slot="{ passed, failed, errors }"
                >
                  <base-input
                    required
                    label="Email"
                    v-model="model.email"
                    type="text"
                    placeholder="Enter member email"
                    :error="errors[0]"
                    :class="[
                      { 'has-success': passed },
                      { 'has-danger': failed },
                    ]"
                  >
                  </base-input>
                </ValidationProvider>
              </div>
              <div class="col-md-8">
                <textarea
                  class="form-text form-control"
                  v-model="model.description"
                  placeholder="Enter description"
                  label="Description"
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
                <br />
              </div>
              <div class="col-md-8">
                <base-button
                  class="mt-4"
                  id="submit"
                  native-type="submit"
                  type="danger"
                  >Add</base-button
                >
              </div>
            </div>
          </form>
        </ValidationObserver>
      </card>
    </div>
    <!-- end card -->
  </div>
</template>
<script>
import db from "@/components/Firebase/firebaseInit";
import { DatePicker } from "element-ui";
import swal from "sweetalert2";
import { extend } from "vee-validate";
import { email, min, numeric, required } from "vee-validate/dist/rules";
extend("email", email);
extend("numeric", numeric);
extend("required", required);
extend("min", min);
export default {
  components: {
    [DatePicker.name]: DatePicker,
  },
  data() {
    return {
      model: {
        firstName: null,
        lastName: null,
        position: null,
        phone: null,
        email: null,
        description: null,
      },
    };
  },
  methods: {
    submit() {
      document.getElementById("submit").disabled = true;
      let idDate = Number(new Date().valueOf());
      // add the data to db
      db.collection("members")
        .add({
          firstName: this.model.firstName,
          lastName: this.model.lastName,
          position: this.model.position,
          email: this.model.email,
          phone: this.model.phone,
          program_id: this.$route.params.program_id,
          startup_id: this.$route.params.startup_id,
          description: this.model.description,
          time: idDate,
        })
        .then((docRef) => {
          db.collection("startups")
            .doc(this.$route.params.startup_doc_id)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                let milestone = Number(doc.data().members) + 1;
                doc.ref
                  .update({
                    members: milestone,
                  })
                  .then(() => {});
              });
            });
          // alert("Succefully Added Data");
          swal.fire({
            title: `Success!`,
            text: "You have succesfully added member",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-danger btn-fill",
            },
            icon: "success",
          });
          this.$router.push("/startups");
        })
        .catch((error) => {
          console.error("Error adding Data: ", error);
        });
    },
  },
};
</script>
<style></style>
