<template>
  <div class="col p-0">
    <i v-if="isLoading" class="fas fa-spinner fa-spin"></i>
    <card v-else class="stacked-form">
      <div
        class="col-12 px-0 d-flex justify-content-between align-items-center"
      >
        <h4 slot="header" class="card-title">Create Score Points</h4>
        <button class="btn btn-outline-default" @click="cancel()">
          Cancel
        </button>
      </div>
      <div class="card col-12 px-0">
        <el-table
          header-cell-class-name="table-transparent"
          header-row-class-name="table-transparent"
          row-class-name="table-transparent"
          :data="model.performanceDataPoints"
        >
          <el-table-column label="#" type="index"></el-table-column>
          <el-table-column min-width="150" sortable label="Name" property="row">
            <div slot-scope="{ row }">
              {{ row }}
            </div>
          </el-table-column>
        </el-table>
        <label>Current Score Points</label>
        <tags-input
          class="my-3"
          placeHolder="Add new scorepoint"
          v-model="model.performanceDataPoints"
        ></tags-input>

        <div class="col-12 mt-4 px-0 justify-content-between d-flex">
          <button class="btn btn-default" @click="submit()">
            Create Score Points
          </button>

          <button class="btn btn-outline-default" @click="cancel()">
            Cancel
          </button>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { TagsInput } from "src/components/index";
import swal from "sweetalert2";
import { mapActions } from "vuex/dist/vuex.common.js";
export default {
  components: {
    TagsInput,
  },
  data() {
    return {
      isLoading: false,
      model: {performanceDataPoints: {}},
    };
  },
  created() {
    const params = this.$route.params;
    if (params.program === undefined) {
      this.$router.go(-1);
    }
    
    this.model = params.program;
  },
  methods: {
    ...mapActions({
        editProgram:"program/editProgram"
    }),
    cancel() {
      this.$router.go(-1);
    },
    async submit() {
      try {
        const router = this.$router;
        // Add new questions to the current additional questions. 

        let payload = {
          programId: this.model.id,
          program: this.model,
        };

        const result = await swal.fire({
          title: `Are you sure you want to recreate score points?`,
          text: "Once changed, all previous evaluations for ALL startups will be lost!",
          showCancelButton: true,
          icon: "question",
          customClass: {
            confirmButton: "btn btn-danger mr-2",
            cancelButton: "btn btn-outline-default",
          },
          confirmButtonText: "Create Score Points",
          buttonsStyling: false,
        });
        if (result.value) {
          this.isLoading = true;
          await this.editProgram(payload);
          swal.fire({
            title: `Success!`,
            text: "Score points created",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-success",
            },
            icon: "success",
          });
          router.go(-1);
        }
        //
      } catch (error) {
        swal.fire({
          title: `Creating Score Points Failed!`,
          text: error.message,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger",
          },
          icon: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
