<template>
  <div>
    <div class="row">
      <div v-if="type == 1" class="col-md-3 col-sm-12 ml-auto">
        <router-link to="/partners/add" replace>
          <base-button class="btn-block" type="danger">
            <i class="fas fa-plus"></i>
            Add new partner</base-button
          >
        </router-link>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-6" v-for="card in statsCards" :key="card.title">
        <router-link
          v-if="type == 1"
          v-bind:to="{
            name: 'Partners-Detail',
            params: { partner_id: card.id },
          }"
        >
          <stats-card-img
            :title="card.title"
            :sub-title="card.subTitle"
            type="danger"
            :icon="card.logo"
          >
            <!-- <div slot="footer" v-html="card.footer"></div> -->
          </stats-card-img>
        </router-link>
        <stats-card-img
          :title="card.title"
          :sub-title="card.subTitle"
          type="danger"
          :icon="card.logo"
          v-if="type != 1"
        >
          <!-- <div slot="footer" v-html="card.footer"></div> -->
        </stats-card-img>
        <!-- <stats-card v-if="type != 1"
            :title="card.title"
            :sub-title="card.subTitle"
            type="danger"
            icon="lni lni-users"
          >
            <div slot="footer" v-html="card.footer"></div>
          </stats-card> -->
      </div>
    </div>
  </div>
</template>
<script>
import StatsCardImg from "src/components/Cards/StatsCardImg";
import { mapGetters } from "vuex";
const data = [
  { dateLabel: "January 2017", title: "Gathering Information" },
  { dateLabel: "February 2017", title: "Planning" },
  { dateLabel: "March 2017", title: "Design" },
  { dateLabel: "April 2017", title: "Content Writing and Assembly" },
  { dateLabel: "May 2017", title: "Coding" },
  { dateLabel: "June 2017", title: "Testing, Review & Launch" },
  { dateLabel: "July 2017", title: "Maintenance" },
];
export default {
  components: {
    StatsCardImg,
  },
  data() {
    return {
      statsCards: [],
      steps: data,
      partners: [],
    };
  },
  created() {
    this.fetchPartners();
  },
  mounted() {
    // var swiper = new Swiper('.swiper-container', {
    //   //pagination: '.swiper-pagination',
    //   slidesPerView: 7,
    //   paginationClickable: true,
    //   grabCursor: true,
    //   paginationClickable: true,
    //   nextButton: '.next-slide',
    //   prevButton: '.prev-slide',
    // });
  },
  methods: {
    ...mapGetters({ getAllPartners: "partner/getAllPartners" }),
    fetchPartners() {
      if (this.type == 4) {
        // Startup partners page
        // // Get Startup by email
        // db.collection("startups")
        //   .where("email", "==", this.$store.state.auth.models.email)
        //   .get()
        //   .then((querySnapshot) => {
        //     querySnapshot.forEach((doc) => {
        //       // Get programs by programID
        //       db.collection("programs")
        //         .doc(programID)
        //         .get()
        //         .then((querySnapshot) => {
        //           querySnapshot.forEach((doc) => {
        //             doc.data().partner.forEach((partner) => {
        //               db.collection("partners")
        //                 .where(
        //                   firebase.firestore.FieldPath.documentId(),
        //                   "==",
        //                   partner.id
        //                 )
        //                 .get()
        //                 .then((querySnapshot) => {
        //                   querySnapshot.forEach((doc) => {
        //                     const data = {
        //                       logo: doc.data().logo,
        //                       id: doc.id,
        //                       title: doc.data().name,
        //                       subTitle: doc.data().email,
        //                     };
        //                     this.statsCards.push(data);
        //                   });
        //                 });
        //             });
        //           });
        //         });
        //     });
        //   });
        } else {
        // Regular Partners Page
        this.getAllPartners().then((_partners) => {
          this.statsCards = _partners;
        } );
        // db.collection("partners")
        //   .get()
        //   .then((querySnapshot) => {
        //     
        //     querySnapshot.forEach((doc) => {
        //       let startups;
        //       if (doc.data().startups == null) {
        //         startups = 0;
        //       } else {
        //         startups = doc.data().startups;
        //       }
        //       let programs;
        //       if (doc.data().programs == null) {
        //         programs = 0;
        //       } else {
        //         programs = doc.data().programs;
        //       }
        //       const data = {
        //         logo: doc.data().logo,
        //         id: doc.id,
        //         title: doc.data().name,
        //         subTitle: doc.data().email,
        //         footer:
        //           "<b>" +
        //           programs +
        //           "</b> Programs, <b> " +
        //           startups +
        //           "</b> Startups",
        //       };
        //       this.statsCards.push(data);
        //     });
        //   });
      }
    },
  },
  computed: {
    ...mapGetters({ type: "auth/type" }),
  },
};
</script>
<style>
</style>
