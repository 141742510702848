<template>
  <div class="col p-0">
    <i v-if="isLoading" class="fas fa-spinner fa-spin"></i>
    <div v-if="!isLoading" class="col-md-12">
      <card class="stacked-form">
        <div class="col-8 d-flex justify-content-between align-items-center">
          <h4 slot="header" class="card-title">Edit Program</h4>
          <base-button class="mt-4" type="default" @click.native="cancel()">Cancel</base-button>
        </div>
        <ValidationObserver>
          <form>
            <div>
              <div class="col-md-8">
                <ValidationProvider name="Name" rules="required|min:3" v-slot="{ passed, failed, errors }">
                  <base-input required label="Name" v-model="model.name" type="text" placeholder="Enter program name"
                    :error="errors[0]" :class="[
      { 'has-success': passed },
      { 'has-danger': failed },
    ]">
                  </base-input>
                </ValidationProvider>
              </div>
              <br />
              <div class="col-md-8">
                <label> Program Start Date </label>
                <ValidationProvider name="Start Date" rules="required" v-slot="{ errors }">
                  <el-date-picker :error="errors[0]" v-model="model.start" type="date" placeholder="Pick a Date" />
                </ValidationProvider>
              </div>
              <br />
              <div class="col-md-8">
                <ValidationProvider name="End Date" rules="required" v-slot="{ errors }">
                  <label> Program End Date </label>
                  <el-date-picker required type="date" v-model="model.end" placeholder="Pick a date" :error="errors[0]">
                  </el-date-picker>
                </ValidationProvider>
              </div>
              <div class="col-md-8">
                <label> Program Description </label>
                <textarea class="form-text form-control" v-model="model.description"
                  placeholder="Enter program description" label="Description" name="" id="" cols="30"
                  rows="10"></textarea>
                <br />
              </div>
              <div class="col-md-8" :v-if="model.additionalQuestions">
                <label>Additional questions (Note: This will change the name of the question for all startups)</label>
                <el-table :data="model.additionalQuestions" v-if="model.additionalQuestions.length != 0" :stripe=true
                  :border=true>
                  <el-table-column label="Question name" prop="question">
                  </el-table-column>
                  <el-table-column width="150" label="Actions" type="expand" :resizable=false>
                    <div class="d-flex justify-content-end" slot-scope="{ row }">
                      <ValidationProvider :name="row.question" rules="required|min:3" class="col p-0"
                        v-slot="{ passed, failed, errors }" :key="row.id">
                        <base-input class="col p-0" required v-model="row.question" type="text"
                          placeholder="Enter new question name" label="Question name" :error="errors[0]" :class="[
      { 'has-success': passed },
      { 'has-danger': failed },
    ]">
                        </base-input>
                      </ValidationProvider>
                      <base-button class="ml-2 justify-content-around d-flex align-items-center" id="submit"
                        @click.native="removeQuestion(row.id)" type="outline-danger" size="sm"> <i
                          class="lni lni-trash-3 mr-2"></i>Remove question</base-button>
                    </div>
                  </el-table-column>
                </el-table>
                <div v-else class="text-center alert alert-dark">
                  No additional questions are available for this program.
                </div>
                <label>Additional new additional questions (Note: This will be required for all startups)</label>
                <tags-input class="my-3" placeHolder="Add new question" v-model="newQuestions"></tags-input>
              </div>
              <div class="col-8 justify-content-between d-flex">
                <base-button class="mt-4" id="submit" @click.native="submit()" type="default">Save</base-button>
                <base-button class="mt-4" @click.native="deleteProgram()" id="delete" type="danger">Delete
                  Program</base-button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </card>
    </div>
    <!-- end card -->
  </div>
</template>
<script>
import { DatePicker, Option, Select } from "element-ui";
// import firebase from 'firebase';
import { TagsInput } from "src/components/index";
import swal from "sweetalert2";
import { extend } from "vee-validate";
import { email, min, numeric, required } from "vee-validate/dist/rules";
import { mapActions } from "vuex";
extend("email", email);
extend("numeric", numeric);
extend("required", required);
extend("min", min);
export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
  
    TagsInput,
  },
  data() {
    return {
      newQuestions: [],
      partners: [],
      programId: null,
      isLoading: true,
      model: {
        name: null,
        start: null,
        end: null,
        description: null,
      },
    };
  },
  async created() {
    this.programId = this.$route.params.program_id;
    this.model = await this.getProgram(this.programId);
    // Filter out questions that do not have ids.
    if (
      this.model.additionalQuestions != undefined &&
      this.model.additionalQuestions.length > 0
    ) {
      this.model.additionalQuestions = this.model.additionalQuestions.filter((value) => value.id != undefined);
      this.model.additionalQuestions.sort(function (a, b) {
        if (a.question == undefined || b.question == undefined) {
          return 0;
        }
        if (a.question < b.question) {
          return -1;
        }
        if (a.question > b.question) {
          return 1;
        }
        return 0;
      });
    }
    let start = new Date(0);
    start.setUTCSeconds(this.model.start.seconds);
    let end = new Date(0);
    end.setUTCSeconds(this.model.end.seconds);
    this.model.start = start;
    this.model.end = end;
    this.isLoading = false;
  },
  watch: {
    $route: "fetchData",
  },
  methods: {
    ...mapActions({
      getProgram: "program/getProgram",
      editProgram: "program/editProgram",
      removeProgram: "program/deleteProgram",
    }),
    cancel() {
      this.$router.go(-1);
    },
    async removeQuestion(id) {
      const result = await swal.fire({
        title: `Are you sure you want to delete this question for ALL startups in the program?`,
        text: "Once deleted, you will not be able to recover this question!",
        showCancelButton: true,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-danger btn-fill mr-2",
          cancelButton: "btn btn-succsess btn-outline",
        },
        confirmButtonText: "Remove question for all startups",
        buttonsStyling: false,
      });
      if (result.value) {
        if (
          this.model.additionalQuestions != undefined &&
          this.model.additionalQuestions.length > 0 && id != undefined
        ) {
          this.model.additionalQuestions = this.model.additionalQuestions.filter((value) => value.id != id);
          swal.fire({
            title: `Question removed`,
            text: `You have succesfully removed the question`,
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-danger btn-fill",
            },
            icon: "info",
          });
        }
      }
    },
    async deleteProgram() {
      const result = await swal.fire({
        title: `Are you sure you want to delete the program "${this.model.name}"?`,
        text: "Once deleted, you will not be able to recover this program!",
        showCancelButton: true,
        icon: "question",
        customClass: {
          confirmButton: "btn btn-danger btn-fill mr-2",
          cancelButton: "btn btn-succsess btn-outline",
        },
        confirmButtonText: "Delete Program",
        buttonsStyling: false,
      });
      if (result.value) {
        await this.removeProgram(this.programId);
        this.$router.go(-1);
        swal.fire({
          title: `Program Removed`,
          text: `You have succesfully deleted the program "${this.model.name}"`,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger btn-fill",
          },
          icon: "info",
        });
      }
    },
    async submit() {
      try {
        this.isLoading = true;
        const router = this.$router;
        // Add new questions to the current additional questions.
        this.model.additionalQuestions.push(...this.newQuestions);
        let payload = {
          programId: this.programId,
          program: this.model,
        };
        // 
        await this.editProgram(payload);
        swal.fire({
          title: `Success!`,
          text: "Changes to program saved",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger btn-fill",
          },
          icon: "success",
        });
        router.go(-1);
      } catch (error) {
        this.isLoading = false;
        swal.fire({
          title: `Editing Program Failed!`,
          text: error.message,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger btn-fill",
          },
          icon: "error",
        });
      }
    },
  },
};
</script>
<style></style>
