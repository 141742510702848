<template>
  <div class="row">
    <div class="col-md-12">
      <i v-if="isLoading" class="fas fa-spinner fa-spin"></i>
      <card v-if="!isLoading" class="stacked-form" title="Stacked Form">
        <div slot="header" class="col-lg-12 d-flex justify-content-between align-items-end">
          <h4 class="card-title mb-0">Edit Startup {{ model.name }}</h4>
          <button class="btn btn-outline-default" @click="cancel()">
            Cancel
          </button>
        </div>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submit)">
            <div>
              <div class="col-lg-12">
                <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                  <base-input required label="Name" v-model="model.name" type="text" placeholder="Enter startup name"
                    :error="errors[0]">
                  </base-input>
                </ValidationProvider>
              </div>
              <div class="col-lg-12 d-flex flex-column">
                <label for="startupSector">Startup Sector</label>
                <ValidationProvider name="Startup Type" rules="required" v-slot="{ errors }">
                  <el-select class="select-default" size="large" name="startupSector"
                    placeholder="Select startup sector" v-model="model.sector" :error="errors[0]">
                    <el-option v-for="option in program.sectors" class="select-danger" :value="option" :label="option"
                      :key="option">
                    </el-option>
                  </el-select>
                </ValidationProvider>
              </div>
              <br />
              <div class="col-lg-12 d-flex flex-column">
                <label for="startupStage">Startup Stage</label>
                <ValidationProvider name="Startup Stage" rules="required" v-slot="{ errors, passed, failed }">
                  <el-select class="select-default" :class="[
                    { 'has-success': passed },
                    { 'has-danger': failed },
                  ]" size="large" name="startupStage" placeholder="Select Startup Stage" v-model="model.stage"
                    :error="errors[0]">
                    <el-option v-for="option in program.stages" class="select-danger" :value="option" :label="option"
                      :key="option">
                    </el-option>
                  </el-select>
                </ValidationProvider>
                <br />
              </div>
              <div class="col-lg-12">
                <label> Business Registartion Date </label>
                <ValidationProvider name="Business Registration Date" rules="required"
                  v-slot="{ errors, passed, failed }">
                  <div :class="[
                    { 'has-success': passed },
                    { 'has-danger': failed },
                  ]" class="form-group">
                    <el-date-picker :error="errors[0]" v-model="model.businessRegistrationDate" type="date"
                      name="Business Registartion Date" placeholder="Pick a Date" />
                    <span v-if="failed" class="error mt-2 mb-0 d-flex align-items-center"><i class="lni lni-xmark-circle mr-2"></i>{{
                      errors[0] }}</span>
                  </div>

                </ValidationProvider>
              </div>
              <div class="col-lg-12">
                <ValidationProvider name="Annual Revenue" rules="required|numeric" v-slot="{ errors, passed, failed }">
                  <base-input required label="Annual Revenue (ETB)" v-model="model.annualRevenue" type="text" :class="[
                    { 'has-success': passed },
                    { 'has-danger': failed },
                  ]" class="mt-2" placeholder="Enter Annual Revenue" :error="errors[0]">
                  </base-input>
                </ValidationProvider>
              </div>
              <div class="col-lg-12">
                <ValidationProvider name="Phone" rules="required|numeric" v-slot="{ errors, passed, failed }">
                  <base-input required label="Phone No." v-model="model.contactInfo.phone" type="text" :class="[
                    { 'has-success': passed },
                    { 'has-danger': failed },
                  ]" placeholder="Enter startup phone No." :error="errors[0]">
                  </base-input>
                </ValidationProvider>
              </div>
              <div class="col-lg-12">
                <ValidationProvider name="Email" rules="required|email" v-slot="{ errors, passed, failed }">
                  <base-input required label="Email" v-model="model.contactInfo.email" type="text" :class="[
                    { 'has-success': passed },
                    { 'has-danger': failed },
                  ]" placeholder="Enter startup email" :error="errors[0]">
                  </base-input>
                </ValidationProvider>
              </div>
              <div class="col-lg-12">
                <ValidationProvider name="Social Media Links" v-slot="{ errors }">
                  <label>Social Media Links</label>
                  <tags-input class="ml-0" placeHolder="https://twitter.com/example"
                    v-model="model.socialMediaLinks"></tags-input>
                  <label class="my-2 text-danger">{{ errors[0] }}</label>
                  <br />
                </ValidationProvider>
              </div>
              <div class="col-lg-12">
                <ValidationProvider name="Address" rules="required|min:3" v-slot="{ errors, passed, failed }">
                  <base-input required label="Address" v-model="model.contactInfo.address" type="text" :class="[
                    { 'has-success': passed },
                    { 'has-danger': failed },
                  ]" placeholder="Yeka Subcity Wereda 12" :error="errors[0]">
                  </base-input>
                </ValidationProvider>
              </div>
              <div class="col-lg-12">
                <ValidationProvider name="City" rules="required|min:3" v-slot="{ errors, passed, failed }">
                  <base-input required label="City" v-model="model.contactInfo.city" type="text" :class="[
                    { 'has-success': passed },
                    { 'has-danger': failed },
                  ]" placeholder="Addis Ababa" :error="errors[0]">
                  </base-input>
                </ValidationProvider>
              </div>
              <div class="col-lg-12">
                <label>Employees</label>
                <div class="row no-gutters p-0">
                  <ValidationProvider name="Male employees" rules="required" v-slot="{ passed, failed, errors }">
                    <base-input addon-left-icon="fa fa-male" class="mr-3" required label="Male" type="number"
                      v-model="model.employees.male" placeholder="0" :error="errors[0]" :class="[
                        { 'has-success': passed },
                        { 'has-danger': failed },
                      ]">
                    </base-input>
                  </ValidationProvider>
                  <ValidationProvider name="Female employees" rules="required" v-slot="{ passed, failed, errors }">
                    <base-input addon-left-icon="fa fa-female" required label="Female" type="number"
                      v-model="model.employees.female" placeholder="0" :error="errors[0]" :class="[
                        { 'has-success': passed },
                        { 'has-danger': failed },
                      ]">
                    </base-input>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-lg-12">
                <ValidationProvider name="Startup Description" rules="required" v-slot="{ passed, failed, errors }">
                  <base-input isTextArea required :error="errors[0]" :class="[
                    { 'has-success': passed },
                    { 'has-danger': failed },
                  ]" v-model="model.description" placeholder="Enter description" type="text"
                    label="Startup Description"></base-input>
                </ValidationProvider>
              </div>
              <div class="col-lg-12 mt-5 d-flex flex-column">
                <h4 class="card-title"> Additional Information </h4>
                <ValidationProvider :name="value.question" rules="required|min:3" v-slot="{ passed, failed, errors }"
                  v-for="value in program.additionalQuestions" :key="value.id">
                  <base-input class="mt-2" required :label="value.question"
                    v-model="model.additionalQuestionsWithAnswers[value.id]" type="text" isTextArea
                    :placeholder="value.question" :error="errors[0]" :class="[
                      { 'has-success': passed },
                      { 'has-danger': failed },
                    ]">
                  </base-input>
                </ValidationProvider>
              </div>
              <div class="col-lg-12">
                <button class="mt-4 btn btn-default" type="submit">Save Changes</button>

              </div>
            </div>
          </form>
        </ValidationObserver>
      </card>
    </div>
    <!-- end card -->
  </div>
</template>
<script>
import { DatePicker, Option, Select } from "element-ui";
import { TagsInput } from "src/components/index";
import swal from "sweetalert2";
import { extend } from "vee-validate";
import { email, min, numeric, required } from "vee-validate/dist/rules";
import { mapActions, mapGetters } from "vuex";
extend("email", email);
extend("numeric", numeric);
extend("required", required);
extend("min", min);
export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
    TagsInput,
  },
  data() {
    return {
      isLoading: true,
      program: null,
      model: null,
    };
  },
  async created() {
    let params = this.$route.params;
    this.model = await this.getStartup(params.program_id, params.startup_id);
    if (this.model.businessRegistrationDate != null) {
      let businessRegistrationDate = new Date(0);
      businessRegistrationDate.setUTCSeconds(
        this.model.businessRegistrationDate.seconds
      );
      this.model.businessRegistrationDate = businessRegistrationDate;
    }
    this.program = await this.getProgram(params.program_id);
    this.isLoading = false;
  },
  watch: {
    $route: "fetchData",
  },
  methods: {
    ...mapActions({
      getProgram: "program/getProgram",
      editStartup: "startup/editStartup",
    }),
    cancel() {
      this.$router.go(-1);
    },
    async submit() {
      try {
        this.isLoading = true;
        const router = this.$router;
        let payload = {
          programId: this.$route.params.program_id,
          startupId: this.$route.params.startup_id,
          startup: this.model,
        };
        await this.editStartup(payload);
        await swal.fire({
          title: `Success!`,
          text: "Changes to startup saved",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger btn-fill",
          },
          icon: "success",
        });
        router.go(-1);
      } catch (error) {
        await swal.fire({
          title: `Editing Startup Failed!`,
          text: error.message,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger btn-fill",
          },
          icon: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      getStartup: "startup/getStartup",
    }),
  },
};
</script>
<style></style>
