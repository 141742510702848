<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form" title="Stacked Form">
        <h4 slot="header" class="card-title">Settings</h4>
        <i v-if="isLoading" class="fas fa-spinner fa-spin"></i>
        <div v-if="!isLoading">
          <div class="col-md-8">
            <h5>Set new password</h5>
            <p>A link to set your password will be sent to <strong>{{ this.currentUser.email }}</strong></p>
            <p>After clicking 'Send password reset link' below, check your email and click the link provided.</p>
          </div>
          <div class="col-md-8">
            <base-button class="mt-4" id="submit" native-type="submit" type="danger" @click.native="submit()">Send
              password reset link</base-button>
          </div>
        </div>
      </card>
    </div>
    <!-- end card -->
  </div>
</template>
<script>
import swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      currentUser: null,
      isLoading: false,
      model: {
        password: null,
        newPassword: null,
        reNewPassword: null
      }
    };
  },
  async created() {
    this.isLoading = true;
    this.currentUser = await this.getCurrentUser();
    this.isLoading = false;
  },
  methods: {
    ...mapActions({
      getCurrentUser: "user/getLoggedInUserData",
      sendPasswordReset: "auth/sendPasswordReset"
    }),
    async submit() {
      try {
        await this.sendPasswordReset(this.currentUser.email);
        swal.fire({
          title: "Success",
          text: `An email has been sent to ${this.currentUser.email}, follow the instructions in the email to set your password.`,
          icon: "success",
          customClass: {
            confirmButton: "btn btn-info",
          },
          buttonsStyling: false,
        });
      } catch (e) {
        swal.fire({
          title: "Error",
          text: `${error}`,
          icon: "error",
          customClass: {
            confirmButton: "btn btn-info",
          },
          buttonsStyling: false,
        });
      }
      // if (this.model.newPassword == this.model.reNewPassword) {
      //   var user = getAuth().currentUser;
      //   var cred = EmailAuthProvider.credential(
      //     user.email, this.model.password);
      //   user.reauthenticateAndRetrieveDataWithCredential(cred).then(() => {
      //     var user = getAuth().currentUser;
      //     user.updatePassword(this.model.newPassword).then(() => {
      //       swal.fire({
      //         title: `Password Updated!`,
      //         text: 'You have updated password succesfully',
      //         buttonsStyling: false,
      //         customClass: {
      //           confirmButton: "btn btn-danger btn-fill"
      //         },
      //         icon: "success"
      //       });
      //       this.$router.push('/dashboard')
      //     }).catch((error) => {
      //       swal.fire({
      //         title: `Error!`,
      //         text: error,
      //         buttonsStyling: false,
      //         customClass: {
      //           confirmButton: "btn btn-danger btn-fill"
      //         },
      //         icon: "error"
      //       });
      //       this.$router.push('/settings')
      //     });
      //   }).catch((error) => {
      //     swal.fire({
      //       title: `Error!`,
      //       text: error,
      //       buttonsStyling: false,
      //       customClass: {
      //         confirmButton: "btn btn-danger btn-fill"
      //       },
      //       icon: "error"
      //     });
      //     this.$router.push('/settings')
      //   });
      // } else {
      //   swal.fire({
      //     title: `Error!`,
      //     text: 'The new passwords doesn\'t match.',
      //     buttonsStyling: false,
      //     customClass: {
      //       confirmButton: "btn btn-danger btn-fill"
      //     },
      //     icon: "error"
      //   });
      //   this.$router.push('/settings')
      // }
    }
  }
};
</script>
<style></style>
