var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('card',{staticClass:"stacked-form",attrs:{"title":"Stacked Form"}},[_c('h4',{staticClass:"card-title",attrs:{"slot":"header"},slot:"header"},[_vm._v("Add New member")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',[_c('div',{staticClass:"col-md-8"},[_c('ValidationProvider',{attrs:{"name":"First Name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('base-input',{class:[
                    { 'has-success': passed },
                    { 'has-danger': failed },
                  ],attrs:{"required":"","label":"First Name","type":"text","placeholder":"Enter First name","error":errors[0]},model:{value:(_vm.model.firstName),callback:function ($$v) {_vm.$set(_vm.model, "firstName", $$v)},expression:"model.firstName"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-8"},[_c('ValidationProvider',{attrs:{"name":"Last Name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('base-input',{class:[
                    { 'has-success': passed },
                    { 'has-danger': failed },
                  ],attrs:{"required":"","label":"Last Name","type":"text","placeholder":"Enter Last name","error":errors[0]},model:{value:(_vm.model.lastName),callback:function ($$v) {_vm.$set(_vm.model, "lastName", $$v)},expression:"model.lastName"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-8"},[_c('ValidationProvider',{attrs:{"name":"Position","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('base-input',{class:[
                    { 'has-success': passed },
                    { 'has-danger': failed },
                  ],attrs:{"required":"","label":"Position","type":"text","placeholder":"Enter position","error":errors[0]},model:{value:(_vm.model.position),callback:function ($$v) {_vm.$set(_vm.model, "position", $$v)},expression:"model.position"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-8"},[_c('ValidationProvider',{attrs:{"name":"Phone","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('base-input',{class:[
                    { 'has-success': passed },
                    { 'has-danger': failed },
                  ],attrs:{"required":"","label":"Phone","type":"text","placeholder":"Enter phone","error":errors[0]},model:{value:(_vm.model.phone),callback:function ($$v) {_vm.$set(_vm.model, "phone", $$v)},expression:"model.phone"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-8"},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('base-input',{class:[
                    { 'has-success': passed },
                    { 'has-danger': failed },
                  ],attrs:{"required":"","label":"Email","type":"text","placeholder":"Enter member email","error":errors[0]},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-8"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.description),expression:"model.description"}],staticClass:"form-text form-control",attrs:{"placeholder":"Enter description","label":"Description","name":"","id":"","cols":"30","rows":"10"},domProps:{"value":(_vm.model.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.model, "description", $event.target.value)}}}),_c('br')]),_c('div',{staticClass:"col-md-8"},[_c('base-button',{staticClass:"mt-4",attrs:{"id":"submit","native-type":"submit","type":"danger"}},[_vm._v("Add")])],1)])])]}}])})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }