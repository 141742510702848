<template>
  <div>
    <i v-if="isLoading" class="fas fa-spinner fa-spin"></i>
    <div v-if="!isLoading">
      <div class="row m-0 align-items-center">
        <h1 class="mb-0">Programs</h1>
        <div v-if="type < 3" class="col-md-3 col-sm-12 ml-auto">
          <router-link to="/programs/add" replace>
            <base-button class="btn-block" type="danger">
              <i class="fas fa-plus"></i>
              Create program</base-button>
          </router-link>
        </div>
      </div>
      <br />
      <div class="row justify-content-center">
        <div v-if="tableData === null">
          <lottie-player src="https://assets9.lottiefiles.com/packages/lf20_fmieo0wt.json" background="transparent"
            speed="1" style="width: 300px; height: 300px; filter: hue-rotate(135deg)" loop autoplay></lottie-player>
          <h3 class="text-center">No programs found</h3>
        </div>
        <div class="col-md-12 card" v-if="tableData != null">
          <el-table :data="tableData">
            <el-table-column min-width="150" label="Program" property="name.toLowerCase()" sortable>
              <div class="program-link" slot-scope="{ row }">
                <router-link v-bind:to="{
                  name: 'Program-Detail',
                  params: { program_id: row.id },
                }">
                  {{ row.name }}
                </router-link>
              </div>
            </el-table-column>
            <el-table-column min-width="200" label="Startups Supported" property="supported" sortable>
              <div slot-scope="{ row }">
                {{ row.supported }}
              </div>
            </el-table-column>
            <el-table-column min-width="150" label="Deadline">
              <div slot-scope="{ row }">
                {{ row.end.toDate() | moment("MMM YYYY") }}
              </div>
            </el-table-column>
            <el-table-column min-width="150" label="Program ID">
              <div slot-scope="{ row }">
                {{ row.id }}
              </div>
            </el-table-column>
            <el-table-column min-width="150" header-align="right" align="right" label="Options" v-if="type < 3">
              <div slot-scope="{ row }" class="text-right table-actions">
                <el-tooltip v-if="type < 3" content="Edit program" effect="light" :open-delay="300" placement="top">
                  <router-link :to="{
                    name: 'Program-Edit',
                    params: { program_id: row.id },
                  }">
                    <button class="btn btn-icon btn-fab btn-outline-info btn-sm">
                      <i class="lni lni-pencil-1"></i>
                    </button>
                  </router-link>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import db from "@/components/Firebase/firebaseInit";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
  
  },
  created() {
    this.fetchPrograms();
  },
  data() {
    return {
      isLoading: true,
      tableData: [],
    };
  },
  computed: {
    ...mapGetters({
      type: "auth/type",
      getProgram: "program/getProgram",
      getAllPrograms: "program/getAllPrograms",
    }),
  },
  methods: {
    ...mapActions({
      userLogOut: "auth/userLogOut",
      getCurrentUser: "user/getLoggedInUserData",
    }),
    async fetchPrograms() {
      let type = this.type;
      if (type != 1) {
        const currentUser = await this.getCurrentUser();
        // 
        if (currentUser) {
          if (
            currentUser.programs == null ||
            currentUser.programs.length == 0
          ) {
            this.tableData = [];
          } else {
            this.tableData = await this.getAllPrograms(currentUser.programs);
          }
        } else {
          await this.userLogOut();
          this.$router.push("/login");
        }
      } else {
        this.tableData = await this.getAllPrograms();
      }
      this.isLoading = false;
    },
    getProgramDataForPartner() {
      let tableData = [];
      db.collection("partners")
        .where("email", "==", this.$store.state.auth.models.email)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let partnerID = doc.id;
            db.collection("partners/" + partnerID + "/programs")
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  let programID = doc.id;
                  this.getProgram(programID).then((program) => {
                    let supported;
                    if (program.startups == null) {
                      supported = 0;
                    } else {
                      supported = doc.data().startups;
                    }
                    const data = {
                      ...program,
                    };
                    data.supported = supported;
                    tableData.push(data);
                  });
                });
              })
              .then(() => (this.isLoading = false));
          });
        });
      return tableData;
    },
  },
};
</script>
<style>
.cell a {
  color: #ec250d !important;
}
</style>
